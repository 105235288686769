import { FC } from 'react';

import { Placeholder } from '@sitecore/common';
import { LineUpRendering } from '@sitecore/types/manual/LineUp';

export const LineUpMainPlaceholder: FC<Pick<LineUpRendering, 'placeholders'>> = ({ placeholders = {} }) => {
  const items = placeholders['jss-line-up-main'];
  const itemsWithRibbons = items?.filter(item => item.fields?.ribbonText?.value).map(item => item.uid) ?? [];

  items?.map((item, index) =>
    Object.assign(item, {
      showRibbonPlaceholder: itemsWithRibbons.length > 0 && !itemsWithRibbons.includes(item.uid),
    }),
  );

  return <Placeholder name="jss-line-up-main" />;
};
